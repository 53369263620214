import React from "react";

export const Base64IntroductionText = () => (
  <React.Fragment>
    <p>
      <a href={"https://en.wikipedia.org/wiki/Base64"} target={"new"}>
        Base64
      </a>{" "}
      is a transport encoding technology designed to send binary data over
      channels which offer reliable transport for{" "}
      <a href={"https://en.wikipedia.org/wiki/ASCII"}>ASCII</a> text only.
    </p>
    <p>
      Base64 was introduced for eMail attachments, but today it is used for
      various other purposes aswell, such as encoding content which is displayed
      in a textfield or used as URL parameter.
    </p>
    <p>
      Both de-and encoding is performed by your browser. Your data is not sent
      anywhere.
    </p>
  </React.Fragment>
);
