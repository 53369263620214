import { useState } from "react";
import { HexDisplay } from "./HexDisplay";
import { HexViewerIntroductionText } from "./HexViewerIntroductionText";

export const HexViewer = () => {
  const [state, setState] = useState({ file: [] });

  const reset = () => {
    setState({ ...state, file: [] });
  };

  const onFileUpload = async ({ target }) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(target.files[0]);
    fileReader.onload = (e) => {
      const bytes = new Uint8Array(e.target.result);

      setState({
        ...state,
        raw: bytes,
      });
    };
  };

  return (
    <div className={"container mx-auto px-4"}>
      <h1 className={"text-2xl"}>Hex Viewer</h1>

      <HexViewerIntroductionText />

      <input
        type="file"
        className="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        onChange={onFileUpload}
      />

      <input
        type="reset"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        onClick={reset}
      />

      {state.raw && state.raw.length > 0 && (
        <HexDisplay data={state.raw}></HexDisplay>
      )}
    </div>
  );
};
