import React from "react";
import { TimestampInput } from "./TimestampInput";
import { IsoTimeStringInput } from "./IsoTimeStringInput";
import { TimestampDatePicker } from "./TimestampDatePicker";
import { CurrentUnixTimestampCard } from "./CurrentUnixTimestampCard";
import { TimestampConverterIntroductionText } from "./TimestampConverterIntroductionText";

export const TimestampConverter = React.memo(({ presetDate }) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const updateTimestamp = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <h1 className={"text-2xl pb-2"}>Unix Timestamp Converter</h1>
      <TimestampConverterIntroductionText />

      <div className={"mt-8"}>
        <div className={"mt-4 mb-4"}>
          <TimestampDatePicker
            presetDate={selectedDate}
            onDateUpdated={updateTimestamp}
          />
        </div>

        <div className={"mt-4 mb-4"}>
          <TimestampInput
            presetDate={selectedDate}
            onDateUpdated={updateTimestamp}
          />
        </div>

        <div className={"mt-4 mb-4"}>
          <IsoTimeStringInput
            presetDate={selectedDate}
            onDateUpdated={updateTimestamp}
          />
        </div>
      </div>
      <div className={"mt-8"}>
        <CurrentUnixTimestampCard></CurrentUnixTimestampCard>
      </div>
    </div>
  );
});
