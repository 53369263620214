import React, { useState } from "react";
import { Base64IntroductionText } from "./Base64IntroductionText";
import { tryDecodeBase64 } from "./Base64";

const Base64TextField = ({ label, value, placeholder, onChange }) => (
  <div>
    <label
      htmlFor="message"
      className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
    >
      {label}
    </label>
    <textarea
      id="message"
      rows="4"
      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder={placeholder}
      defaultValue={value}
      onChange={onChange}
    ></textarea>
  </div>
);

export default function Base64Codec() {
  const [state, setState] = useState({
    plaintext: "",
    base64Text: "",
    base64Valid: true,
  });

  const plaintextUpdated = (event) => {
    const plaintext = event.target.value;
    const base64Text = window.btoa(unescape(encodeURIComponent(plaintext)));
    setState({ ...state, plaintext, base64Text });
  };

  const base64TextUpdated = (event) => {
    const base64Text = event.target.value;

    const [decodedPlaintext, base64Valid] = tryDecodeBase64(base64Text);

    setState({
      ...state,
      base64Text,
      base64Valid,
      plaintext: base64Valid ? decodedPlaintext : state.plaintext,
    });
  };

  return (
    <div className={" container mx-auto px-4"}>
      <h1 className={" text-2xl"}>Base64 Encoder / Decoder</h1>
      <Base64IntroductionText />

      <Base64TextField
        value={state.plaintext}
        onChange={plaintextUpdated}
        placeholder={
          "Paste or type and text here. It will be encoded instantly"
        }
        label=" Plaintext"
      />

      <Base64TextField
        value={state.base64Text}
        onChange={base64TextUpdated}
        placeholder={
          "Paste (or type :-)) your Base64 content here. It will be decoded instantly"
        }
        label="Base64 encoded"
      />
    </div>
  );
}
/*
 <Grid>
                <Grid xs={12}>
                    <TextField
                        id=" outlined-multiline-static"
                        value={state.plaintext}
                        onChange={plaintextUpdated}
                        placeholder={" Paste or type and text here. It will be encoded instantly"}
                        label=" Plaintext"
                        multiline
                        rows={6}
                        variant=" outlined"
                        fullWidth
                    />
                </Grid>
                <br/>
                <Grid xs={12}>
                    <TextField
                        id=" outlined-multiline-static"
                        label=" Base64 encoded"
                        value={state.base64Text}
                        onChange={base64TextUpdated}
                        placeholder={"Paste (or type :-)) your Base64 content here. It will be decoded instantly"}
                        multiline
                        rows={6}
                        error={!state.base64Valid}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
 */
