import React from "react";

export const TimestampConverterIntroductionText = () => (
  <React.Fragment>
    <p className={"text-justify"}>
      Hi, you might probably come along because you want to convert a{" "}
      <a href={"https://en.wikipedia.org/wiki/Unix_time"}>Unix timestamp</a>{" "}
      (also known as POSIX timestamp or Epoch timestamp) to a human-readable
      time or vice-versa. Unix timestamps are, due to their simplicity, used in
      many places nowadays, from time representations in databases or
      filesystems.{" "}
    </p>
    <br />
    <p className={"text-justify"}>
      Unix time is represented as an integer with the number of seconds elapsed
      since 1st January 1970, 00:00:00 UTC. With the converter below, one can
      convert between Unix Timestamps, your local date and time and{" "}
      <a href={"https://en.wikipedia.org/wiki/ISO_8601"}>ISO-8601</a>{" "}
      Timestrings.
    </p>
  </React.Fragment>
);
