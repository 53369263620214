import React from "react";

export const HexViewerIntroductionText = () => (
  <React.Fragment>
    <p>
      Viewing any file in{" "}
      <a href={"https://en.wikipedia.org/wiki/Hexadecimal"} target={"new"}>
        Hex
      </a>{" "}
      lets you dive into a file and reveal details beyond the plain text editor.
      As a nerd, you might originally had your first acquaintance with a Hex
      Viewer on your home computer to find game cheats or fixing media
      duplication issues.
    </p>
    <p>
      Just click on upload to upload a file to the hex viewer. The entire
      processing is done by your browser, so your data is not sent anywhere.
    </p>
  </React.Fragment>
);
