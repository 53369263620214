import React from "react";
import { useTimer } from "../../hooks/useTimer";

export const CurrentUnixTimestampCard = () => {
  const [currentDate, setDate] = React.useState(new Date());

  useTimer(() => {
    setDate(new Date());
  }, 1000);

  return (
    <div
      className={
        "bg-white rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700"
      }
    >
      <div className="border-t border-gray-200 dark:border-gray-600">
        <div
          className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800"
          aria-labelledby="stats-tab"
        >
          <dl className="grid grid-cols-1 gap-8 p-4 mx-auto  text-gray-900 sm:grid-cols-2 xl:grid-cols-2 dark:text-white sm:p-8">
            <div className="flex flex-col justify-center items-center">
              <dt className="mb-2 text-3xl font-extrabold">
                {" "}
                {Math.floor(currentDate.getTime() / 1000)}
              </dt>
              <dd className="font-light text-gray-500 dark:text-gray-400">
                Current Unix Timestamp
              </dd>
            </div>
            <div className="flex flex-col justify-center items-center">
              <dt className="mb-2 text-1xl font-extrabold">
                {currentDate.toISOString()}
              </dt>
              <dd className="font-light text-gray-500 dark:text-gray-400">
                Current ISO Timestring
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
