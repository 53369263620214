import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const NavigationDrawer = ({ visible, setVisible }) => {
  useEffect(() => {}, [visible]);

  const developerTools = [
    {
      title: "Timestamp Converter",
      target: "unix-timestamp-converter",
    },
    {
      title: "Base64 Encoder/Decoder",
      target: "base64-encoder-decoder",
    },
    {
      title: "HexViewer",
      target: "hex-viewer",
    },
  ];

  const ceremony = [
    {
      title: "Imprint / Privacy",
      target: "imprint",
    },
  ];

  const closeDrawer = () => setVisible(false);

  return (
    <aside
      id="sidebar"
      className={`fixed inset-0 z-0 mt-8 ml-0 sm:pl-4 lg:ml-5 flex-none ${
        visible ? "block" : "hidden"
      } h-full w-72 lg:static lg:h-auto lg:overflow-y-visible lg:pt-0 lg:w-48 lg:block sm:border-2 md:border-0`}
      aria-labelledby="sidebar-label"
    >
      <div
        id="navWrapper"
        className="overflow-y-auto z-20 h-full bg-white scrolling-touch max-w-2xs lg:h-[calc(100vh-3rem)] lg:block lg:sticky top:24 lg:top-28 dark:bg-gray-900 lg:mr-0"
      >
        <ul className="mb-0 list-unstyled">
          <li className="mt-8">
            <h5 className="mb-2 text-sm font-semibold tracking-wide text-gray-900 uppercase lg:text-xs dark:text-white">
              Tools
            </h5>
            <ul className="py-1 list-unstyled fw-normal small">
              {developerTools.map(({ title, target }) => (
                <InternalLink
                  key={title}
                  title={title}
                  target={target}
                  onClick={closeDrawer}
                />
              ))}
            </ul>
          </li>
        </ul>

        <ul className="mb-0 list-unstyled">
          <li className="mt-8">
            <h5 className="mb-2 text-sm font-semibold tracking-wide text-gray-900 uppercase lg:text-xs dark:text-white">
              General
            </h5>
            <ul className="py-1 list-unstyled fw-normal small">
              {ceremony.map(({ title, target }) => (
                <InternalLink
                  key={title}
                  title={title}
                  target={target}
                  onClick={closeDrawer}
                />
              ))}
            </ul>
          </li>
          <li>
            <ExternalLink target={"https://dreese.de"} title={"Blog"} />
          </li>
        </ul>
      </div>
    </aside>
  );
};

const InternalLink = ({ title, target, onClick }) => (
  <Link to={target} onClick={onClick}>
    <li className="py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500 dark:text-gray-400 dark:hover:text-white ">
      {title}
    </li>
  </Link>
);

const ExternalLink = (props) => (
  <a href={props.target} target={"_top"}>
    <li className="py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500 dark:text-gray-400 dark:hover:text-white ">
      {props.title}
    </li>
  </a>
);
