import React, { useEffect } from "react";
import { isValid, parseISO } from "date-fns";

export const IsoTimeStringInput = ({ presetDate, onDateUpdated }) => {
  const [state, setState] = React.useState({
    content: presetDate.toISOString(),
    date: presetDate,
    valid: true,
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      valid: true,
      content: presetDate.toISOString(),
      date: presetDate,
    }));
  }, [presetDate]);

  const handleTimestampChange = (event) => {
    const input = event.target.value;
    const newDate = parseISO(input);

    const valid = isValid(newDate);

    if (valid) {
      onDateUpdated(newDate);
    }

    setState({
      ...state,
      valid,
      content: valid ? newDate.toISOString() : input,
      date: newDate,
    });
  };

  return (
    <div>
      <label
        htmlFor="iso8601input"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        ISO 8601 Timestring
      </label>
      <input
        type={"text"}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        id="iso8601input"
        margin="normal"
        value={state.content}
        onChange={handleTimestampChange}
        helperText={!state.valid ? "This is not a valid Timestamp." : ""}
        error={!state.valid}
      />
    </div>
  );
};
