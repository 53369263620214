import { Route } from "react-router-dom";
import { TimestampConverter } from "../TimestampConverter/TimestampConverter";
import Base64Codec from "../Base64Codec/Base64Codec";
import { ImprintPage } from "../Imprint/ImprintPage";
import React from "react";
import { HexViewer } from "../HexViewer/HexViewer";

export const MainRouterOutlet = () => {
  return (
    <div className={"pt-10 mt-8"}>
      <Route exact path="/">
        <TimestampConverter />
      </Route>
      <Route path="/unix-timestamp-converter">
        <TimestampConverter />
      </Route>
      <Route path="/base64-encoder-decoder">
        <Base64Codec />
      </Route>
      <Route path="/hex-viewer">
        <HexViewer />
      </Route>
      <Route path="/imprint">
        <ImprintPage />
      </Route>
    </div>
  );
};
