import { HexTableRow } from "./HexTableRow";
import { HexTableCell } from "./HexTableCell";
import { useEffect } from "react";
import { ASCIITableCell } from "./ASCIITableCell";

export const HexDisplay = ({ data }) => {
  useEffect(() => {}, [data]);

  const splitToChunks = (array, chunkSize) =>
    // eslint-disable-next-line no-sequences
    array === undefined
      ? []
      : array.reduce((acc, e, i) => {
          return (
            i % chunkSize ? acc[acc.length - 1].push(e) : acc.push([e]), acc
          );
        }, []);

  const hex = (i) => (i === undefined ? "" : i.toString(16));

  const ascii = (row) => row.map((it) => String.fromCharCode(it));

  const asciiLeftRight = (row) => splitToChunks(ascii(row), 8);

  return (
    <table aria-label="hex table" className={"table-fixed"}>
      <thead>
        <tr>
          <th>Address</th>
          <th>+0</th>
          <th>+1</th>
          <th>+2</th>
          <th>+3</th>
          <th>+4</th>
          <th>+5</th>
          <th>+6</th>
          <th>+7</th>
          <th></th>
          <th>+8</th>
          <th>+9</th>
          <th>+a</th>
          <th>+b</th>
          <th>+c</th>
          <th>+d</th>
          <th>+e</th>
          <th className={"pr-10"}>+f</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {splitToChunks(data, 16).map((row, idx) => (
          <HexTableRow key={idx}>
            <td>{hex(idx * 16)}</td>
            <HexTableCell classNameX={"hexCell"} align="right">
              {hex(row[0])}
            </HexTableCell>
            <HexTableCell>{hex(row[1])}</HexTableCell>
            <HexTableCell>{hex(row[2])}</HexTableCell>
            <HexTableCell>{hex(row[3])}</HexTableCell>
            <HexTableCell>{hex(row[4])}</HexTableCell>
            <HexTableCell>{hex(row[5])}</HexTableCell>
            <HexTableCell>{hex(row[6])}</HexTableCell>
            <HexTableCell>{hex(row[7])}</HexTableCell>
            <HexTableCell></HexTableCell>
            <HexTableCell>{hex(row[8])}</HexTableCell>
            <HexTableCell>{hex(row[9])}</HexTableCell>
            <HexTableCell>{hex(row[10])}</HexTableCell>
            <HexTableCell>{hex(row[11])}</HexTableCell>
            <HexTableCell>{hex(row[12])}</HexTableCell>
            <HexTableCell>{hex(row[13])}</HexTableCell>
            <HexTableCell>{hex(row[14])}</HexTableCell>
            <HexTableCell>{hex(row[15])}</HexTableCell>
            <ASCIITableCell>{asciiLeftRight(row, 8)[0]}</ASCIITableCell>
            <ASCIITableCell>{asciiLeftRight(row, 8)[1]}</ASCIITableCell>
          </HexTableRow>
        ))}
      </tbody>
    </table>
  );
};
