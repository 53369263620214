import { useState } from "react";
import { NavigationDrawer } from "../NavigationDrawer/NavigationDrawer";

import { BrowserRouter as Router } from "react-router-dom";
import { MainRouterOutlet } from "./MainRouterOutlet";
import { Titlebar } from "../Titlebar/Titlebar";

export const Indexpage = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const setVisible = (visible) => {
    setMobileOpen(visible);
  };

  return (
    <div className="flex">
      <Titlebar onBurgerClick={handleDrawerToggle} />
      <Router>
        <NavigationDrawer visible={mobileOpen} setVisible={setVisible} />
        <div className={"container"}>
          <MainRouterOutlet />
        </div>
      </Router>
    </div>
  );
};
